import React, { useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../store/actions'
// import clsx from "clsx";
import { useHistory } from 'react-router'

import { formatMonetaryValue } from 'utils'
import { isLoggedIn } from 'store/selectors'
// import css from "../../index.module.scss";

import DiscountBanner from '../DiscountBanner'
import QuantityActionButtons from './QuantityActionButtons'
import BenefitTierDetails from '../BenefitTierDetails'
import { CartIcon } from 'assets/icons'

import styles from './styles.module.scss'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@mui/material'

export default function () {
  const history = useHistory()
  const dispatch = useDispatch()

  const product = useSelector(({ product }) => product.product)

  const ticketPrices = useSelector(({ product }) => product.ticketPrices)
  const checkoutInfo = useSelector(({ product }) => product.checkout)
  const userIsLogged = useSelector(isLoggedIn)
  const hasActiveSubscription = useSelector(
    (state) => state.user.info?.subscription_status?.active || false,
  )

  const [selected, setSelected] = useState(ticketPrices[0])
  const [hasMessage, setHasMessage] = useState(null)

  const goToCart = () => {
    if (checkoutInfo.order.length > 0) {
      const alreadyProduct = checkoutInfo.order.find(item => item.price_id === selected.id);

      if (alreadyProduct) {
        history.push('/carrinho');
      } else {
        dispatch(Actions.addToCart(selected.id, selected.ticket_id));
        history.push('/carrinho');
      }
    } else {
      dispatch(Actions.addToCart(selected.id, selected.ticket_id));
    }

    if (checkoutInfo.order.length > 0) {
      localStorage.setItem('AU_order', JSON.stringify(checkoutInfo));
      history.push('/carrinho');
    }
  }

  const getProduct = async (id, price_id, quantity) => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/products/${id}`)
      .then(function (resp) {
        let price = resp.data.tickets
          .map((obj) => {
            var array = obj.prices.filter((price) => {
              return price.id === price_id
            })
            return array[0]
          })
          .filter(function (el) {
            return el != null
          })
        if (price.length > 0) {
          let product = {
            id: resp.data.id,
            name: resp.data.title,
            photo: resp.data?.images[0]?.url,
            price_id,
            quantity,
            value: price[0].price_in_money,
            status: resp.data.status,
          }
          return product
        } else {
          return null
        }
      })
  }

  const addToCart = () => {
    dispatch(Actions.addToCart(selected.id, selected.ticket_id))
    setHasMessage('Você adicionou um produto ao seu carrinho')
    localStorage.setItem('AU_order', JSON.stringify(checkoutInfo))

    const orders = JSON.parse(localStorage.getItem('AU_order'))

    if (orders) {
      const orderProducts = orders

      if (orderProducts.order.length) {
        let products = orderProducts.order.map(async (product) => {
          if (product.quantity > 0 && product.quantity <= 10) {
            return await getProduct(
              product.id,
              product.price_id,
              product.quantity,
            )
          }
        })

        Promise.all(products).then((products) => {
          let filteredProducts = products.filter(
            (product) => product && product.status === 'No Ar',
          )

          const total = filteredProducts.reduce(function (prev, cur) {
            if (cur && cur.quantity > 0 && cur.quantity <= 10) {
              return prev + cur.value * cur.quantity
            } else {
              return 0
            }
          }, 0)

          localStorage.setItem(
            'AU_order',
            JSON.stringify({
              order: filteredProducts,
              total,
              updatedAt: Date.now(),
              use_gift_vouchers: true,
            }),
          )
        })
      }
    }

    history.push('/carrinho')
  }

  const handleSelected = (id) => {
    const ticketPrice = ticketPrices.find((ticket) => ticket.id === id)
    setSelected(ticketPrice)
  }

  return (
    <div className={styles.container}>
      <div>
        <Snackbar
          open={hasMessage}
          autoHideDuration={3000}
          onClose={() => setHasMessage(null)}
        >
          <Alert
            onClose={() => setHasMessage(null)}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {hasMessage}
          </Alert>
        </Snackbar>

        {parseInt(selected.percent_discount || 0) > 0 && (
          <DiscountBanner value={selected.percent_discount} />
        )}
      </div>
      <p className={styles.title}>{product.title}</p>
      <p className={styles.subtitle}>{product.label_place}</p>
      {userIsLogged ? (
        <div className="d-flex flex-column align-self-end justify-content-between w-100 position-relative">
          <select
            className={styles.select}
            onChange={(e) => handleSelected(e.target.value)}
            defaultValue={ticketPrices[0].id}
          >
            {ticketPrices.map((price) => (
              <option key={price.id} name={price.ticket_label} value={price.id}>
                {price.ticket_label.replaceAll(' - ', '')}
              </option>
            ))}
          </select>
          <div className={styles.boxPriceQuantity}>
            <div className={styles.price}>
              <p>
                <span>
                  {formatMonetaryValue(selected.price_in_money_before_sale)}
                </span>
                {formatMonetaryValue(selected.price_in_money)}
              </p>
            </div>
            <div className={styles.quantity}>
              <QuantityActionButtons
                ticketPrice={selected}
                setHasMessage={setHasMessage}
              />
            </div>
          </div>
          <BenefitTierDetails
            ticket={Array.isArray(product?.tickets) ? product.tickets[0] : []}
          />

          {hasActiveSubscription && (
            <div className={styles.btns}>
              <button
                aria-label="adicionar ao carrinho"
                onClick={() => addToCart()}
                className={styles.btnAddToCart}
              >
                <CartIcon />
                Adicionar ao carrinho
              </button>

              <button
                aria-label="comprar"
                onClick={() => goToCart()}
                className={styles.btnComprar}
              >
                Comprar
              </button>
            </div>
          )}

          {/* {hasActiveSubscription ? (
            <button
              aria-label="comprar"
              onClick={() => goToCart()}
              className={clsx(css["btn-produto"], css["btn-laranja"])}
            >
              Comprar
            </button>
          ) : (
            <button
              aria-label="ver planos"
              onClick={() => history.push(`/partners`)}
              className={clsx(css["btn-produto"], css["btn-laranja"])}
            >
              Acessar
            </button>
          )} */}
        </div>
      ) : (
        <>
          {/* <div className={"position-relative pt-4 mb-4"}>
            <DiscountBanner value={ticketPrices[0]?.percent_discount} />
          </div>

          <button
            aria-label="ver preço"
            onClick={() => history.push(`/partners`)}
            className={clsx(css["btn-produto"], css["btn-laranja"])}
          >
            Acessar
          </button> */}
        </>
      )}
    </div>
  )
}
