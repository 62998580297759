import React, { useCallback, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Grid, Box } from '@mui/material'
import { getPartners } from 'services/api'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './index.css'
import { useParams } from 'react-router-dom'
import imageFooter from './images/footer-easylive.svg'
import { useStyles } from './useStyles'
import PageLoading from 'components/pageLoading'
import theme from 'utils/theme'
import FirstAccess from './components/first-access'
import AlreadyClient from './components/already-client'

import { setPartnerSettings } from 'store/actions'

const LandingPagePartners = () => {
  const classes = useStyles()
  const history = useHistory()
  const [partners, setPartners] = useState([])
  const [selectedPartner, setSelectedPartner] = useState()
  const [isFirstAccess, setIsFirstAccess] = useState(false)
  const dispatch = useDispatch()
  const partnerSettings = useSelector((state) => state.global.partner)

  const { partner } = useParams()

  const loadPartners = () => {
    getPartners()
      .then((response) => {
        setPartners(response.partners)
      })
      .catch((error) => {
        console.error({ error })
      })
  }

  useEffect(() => {
    loadPartners()
  }, [])

  const handleSetPartnerSettings = useCallback(() => {
    dispatch(setPartnerSettings(selectedPartner))
  }, [dispatch, selectedPartner])

  useEffect(() => {
    if (selectedPartner) {
      handleSetPartnerSettings()
    }
  }, [handleSetPartnerSettings, selectedPartner])

  useEffect(() => {
    if (partner === 'cluberh') {
      history.push('/rhrio')
    }
    if (partners.length > 0 && partner) {
      const findPartner = partners.find((p) => p.slug === partner)
      if (findPartner) {
        setSelectedPartner(findPartner)
      } else {
        history.push('/')
      }
    }
    // eslint-disable-next-line
  }, [partners.length, partner])

  useEffect(() => {
    if (partnerSettings && partnerSettings.google_tag_manager_id) {
      const tagManagerArgs = {
        gtmId: partnerSettings.google_tag_manager_id,
      }
      TagManager.initialize(tagManagerArgs)
    } else {
      const tagManagerArgs = {
        gtmId: 'GTM-KQ36KMB',
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [partnerSettings])

  return (
    <PageLoading>
      <Box>
        <Grid container>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <Box className={classes.peopleBackground} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <Box
              bgcolor={selectedPartner?.main_colors?.background_color}
              color={'white'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              paddingBottom={'80px'}
              height={'680px'}
              sx={{
                [theme.breakpoints.down('sm')]: {
                  // height: '300px',
                  // paddingBottom: '30px',
                },
              }}
            >
              <>
                <Box className={classes.containerLoginLogo}>
                  {selectedPartner?.logo ? (
                    <img
                      src={selectedPartner?.logo}
                      alt={'logo'}
                      className={classes.loginLogo}
                    />
                  ) : (
                    <Box
                      sx={{
                        fontFamily: 'NeuePlak-Regular',
                        fontSize: 24,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        color: '#333',
                      }}
                    >
                      {selectedPartner?.name}
                    </Box>
                  )}
                </Box>
                <Box className={classes.containerForm}>
                  {!isFirstAccess && (
                    <AlreadyClient
                      selectedPartner={selectedPartner}
                      setIsFirstAccess={setIsFirstAccess}
                    />
                  )}

                  {isFirstAccess && (
                    <FirstAccess
                      selectedPartner={selectedPartner}
                      setIsFirstAccess={setIsFirstAccess}
                    />
                  )}
                </Box>
              </>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} order={{ xs: 3, sm: 3 }}>
            <Box
              p={2}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              height={'100%'}
            >
              {selectedPartner?.logo ? (
                <>
                  <img
                    src={selectedPartner?.logo}
                    alt="mais alegria logo"
                    className={classes.logo}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    color: '#33333',
                    fontFamily: 'NeuePlak-Regular',
                    fontSize: 34,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    [theme.breakpoints.down('sm')]: {
                      marginTop: '20px',
                    },
                  }}
                >
                  {selectedPartner?.name}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 4, sm: 4 }}>
            <Box
              p={2}
              sx={{
                marginBottom: '40px',
                marginTop: '40px',
                [theme.breakpoints.down('sm')]: {
                  marginTop: '10px',
                  marginBottom: '20px',
                },
              }}
            >
              <Box
                sx={{
                  fontFamily: 'NeuePlak-Regular',
                  fontSize: 32,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '40px',
                  color: '#5A5A5A',
                  width: '75%',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    lineHeight: '24px',
                    textAlign: 'center',
                    width: '100%',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  },
                }}
              >
                Sem a necessidade de acumular pontos e com mecânica simples,
              </Box>
              <Box
                sx={{
                  fontFamily: 'NeuePlak-Regular',
                  fontSize: 32,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '40px',
                  color: '#5A5A5A',
                  width: '70%',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    textAlign: 'center',
                    lineHeight: '24px',
                    width: '100%',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                  },
                }}
              >
                um clube de benefícios com descontos em diversas categorias.
              </Box>
              <Box
                sx={{
                  fontFamily: 'NeuePlak-Regular',
                  fontSize: 32,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '40px',
                  color: '#5A5A5A',
                  width: '80%',
                  marginTop: '40px',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    textAlign: 'center',
                    lineHeight: '24px',
                    width: '100%',
                  },
                }}
              >
                Para aderir, basta se cadastrar.
              </Box>
              <Box
                sx={{
                  fontFamily: 'NeuePlak-Black',
                  fontSize: 32,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '40px',
                  color: '#5A5A5A',
                  width: '80%',
                  [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                    textAlign: 'center',
                    lineHeight: '24px',
                    width: '100%',
                  },
                }}
              >
                Fácil não?
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} h={69} order={{ xs: 5, sm: 5 }}>
            <Box
              bgcolor="lightgray"
              p={2}
              textAlign="center"
              sx={{
                [theme.breakpoints.down('sm')]: {
                  marginTop: '20px',
                },
              }}
            >
              <img src={imageFooter} alt="mais alegria logo" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageLoading>
  )
}

export default LandingPagePartners
