import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isLoggedIn } from 'store/selectors'

import { formatMonetaryValue, classNames, scrollTo } from 'utils'
import styles from './index.module.scss'

import moment from 'moment'

import { Card } from 'react-bootstrap'
moment().format()

function productIsNew(createdAt = null) {
  if (createdAt) {
    return moment().diff(moment(createdAt), 'days', false) < 45
  }

  return false
}

const CardBox = ({
  image,
  title,
  text,
  fullprice,
  discountPrice,
  discountPercent,
  product,
  hidePrice = false,
  size = 'default',
  btnFunction = null,
  btnText,
}) => {
  const history = useHistory()

  const logged = useSelector(isLoggedIn)

  const discountFlag = (full, discount) => {
    if (discountPercent) return parseInt(discountPercent)
    else if (discount > 0) {
      const calc = parseInt((discount / full) * 100)
      return 100 - calc
    } else {
      return parseInt(0)
    }
  }

  const openProductPage = () => {
    scrollTo('.header')
    window.location.href = `/produto/${product.slug}`
  }

  const handleClick = () => {
    if (btnFunction) {
      btnFunction()
    } else {
      if (logged) {
        openProductPage()
      } else {
        history.push(`/partners`)
      }
    }
  }

  return (
    <>
      <Card
        className={
          size === 'default'
            ? classNames(styles.cardGrid)
            : classNames(styles.cardGridSmall)
        }
      >
        <Card.Img
          variant="top"
          src={image}
          className={styles.curvedImage}
          alt={title}
          onClick={() => openProductPage()}
        />
        {Boolean(discountFlag(fullprice, discountPrice)) && (
          <div className={styles.imgDesconto}>
            <span className={styles.imgDescontoText1}>
              {discountFlag(fullprice, discountPrice)}%
              <span className={styles.imgDescontoText2}> OFF</span>
            </span>
          </div>
        )}

        <Card.Body className={styles.cardBody}>
          <Card.Title
            onClick={() => openProductPage()}
            className={styles.cardTitle}
          >
            {productIsNew(product?.created_at) && (
              <NewProductTag onClick={() => openProductPage()} />
            )}
            {title}
          </Card.Title>
          {/* <Card.Text
            onClick={() => history.push(`/produto/${product.slug}`)}
            className={styles.cardText}
          >
            {text}
          </Card.Text> */}
          {logged && !hidePrice && (
            <div className={styles.cardPriceWrapper}>
              <p className={styles.discountPrice}>
                {formatMonetaryValue(discountPrice)}
              </p>
              {fullprice !== discountPrice && (
                <p className={styles.fullPrice}>
                  {formatMonetaryValue(fullprice)}
                </p>
              )}
            </div>
          )}
        </Card.Body>
        <Card.Footer className={styles.cardFooter}>
          <button
            aria-label="comprar ou ver preço"
            type="button"
            onClick={() => history.push(`/produto/${product.slug}`)}
            className={styles.btnLaranja}
          >
            {logged ? (btnText ? btnText : `Comprar`) : `Ver preço`}
          </button>
        </Card.Footer>
      </Card>
      <Card className={classNames(styles.cardGridMobile)}>
        <Card.Img
          variant="top"
          src={image}
          className={styles.curvedImageMobile}
          alt={title}
          onClick={() => openProductPage()}
        />
        {Boolean(discountFlag(fullprice, discountPrice)) && (
          <div className={styles.imgDescontoMobile}>
            <span className={styles.imgDescontoText1}>
              {discountFlag(fullprice, discountPrice)}%
              <span className={styles.imgDescontoText2}> OFF</span>
            </span>
          </div>
        )}

        <Card.Body className={styles.cardBodyMobile}>
          <Card.Title
            onClick={() => openProductPage()}
            className={styles.cardTitle}
          >
            {productIsNew(product?.created_at) && (
              <NewProductTag onClick={() => openProductPage()} />
            )}
            {title}
          </Card.Title>
          <Card.Text
            onClick={() => history.push(`/produto/${product.slug}`)}
            className={styles.cardText}
          >
            {text}
          </Card.Text>
          {logged && !hidePrice && (
            <div className={styles.cardPriceWrapper}>
              {fullprice !== discountPrice && (
                <p className={styles.fullPrice}>
                  {formatMonetaryValue(fullprice)}
                </p>
              )}
              <p className={styles.discountPrice}>
                {formatMonetaryValue(discountPrice)}
              </p>
            </div>
          )}
          <Card.Footer className={styles.cardFooter}>
            <button
              aria-label="comprar"
              type="button"
              onClick={handleClick}
              className={styles.btnLaranja}
            >
              {logged ? (btnText ? btnText : `Comprar`) : `Ver preço`}
            </button>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  )
}

CardBox.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  fullprice: PropTypes.number,
  discountPrice: PropTypes.number,
}

CardBox.defaultProps = {
  image: 'https://via.placeholder.com/470x470.png?text=Foto%20em%20breve',
  title: null,
  text: null,
  fullprice: 0,
  discountPrice: 0,
}

export default CardBox

const NewProductTag = (props) => {
  return (
    <div {...props} className={styles.newProductTag}>
      Novidade
    </div>
  )
}
