import React from "react";
import { useSelector } from "react-redux";

import Breadcumb from "components/breadcumb";
// import CarouselContainer from "./components/CarouselContainer";
import { ThumbsCarousel } from './components/ThumbsCarousel'
import TicketAndPriceSection from "./components/TicketAndPriceSection";
import ProductsRelated from "components/productsRelated";
import OffersHighlights from "components/offersHighlight";
import { Tabs } from "./components/Tabs/Tabs";
// import InformationBanner from "./components/InformationBanner";

import styles from "./index.module.scss"

export default function ProductDetails() {
  const product = useSelector(({ product }) => product.product);

  return (
    <div>
      <div>
        <div className={styles.breadcumb}>
          <Breadcumb currentPath={[{ route: `Categoria / ${product.category.name}`, name: product.category.name }]} />
        </div>

        <div className="row mt-2">
          <div className="col-12 col-md-6 pl-5 pr-5">
            <ThumbsCarousel />
            {/* <CarouselContainer /> */}
          </div>

          <div className="col-12 col-md-6">
            <TicketAndPriceSection />
          </div>
        </div>

        <div className="container">
          <div>
            <Tabs />
          </div>
        </div>
      </div>

      {/* <InformationBanner /> */}

      <div className="container">
        {/* <OffersHighlights /> */}
        <ProductsRelated
          relatedTo={[product]}
          noResultsComponent={<OffersHighlights />}
        />
      </div>
    </div>
  );
}
