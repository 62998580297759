import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { setActiveInterestArea } from "store/actions";

import NewLayoutHeader from 'components/NewLayoutHeader'
import AllCategories from 'components/AllCategories'
import Footer from 'components/footer'
import HomeBanner from 'components/banner'
import OffersHighlights from 'components/offersHighlight'
import OutPartners from 'components/ourPartners'
import PageLoading from 'components/pageLoading'

import ProductsList from './components/productsList'

import { isLoggedIn } from "store/selectors";

import styles from './styles.module.scss'

const Categoreis = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const interestAreas = useSelector((state) => state.global?.interestAreas);
  const logged = useSelector(isLoggedIn);
  let interestArea = props.match.params.interest_area;

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem("AU_header"));
    if (!logged && !userHeader) {
      history.push("/partners");
    }
  }, [history, logged]);

  useEffect(() => {
    dispatch(setActiveInterestArea(interestArea));
  }, [props, dispatch, interestAreas, interestArea]);

  return (
    <PageLoading>
      <NewLayoutHeader />
      <AllCategories />
      <HomeBanner />
      <hr className={styles.line}/>

      <ProductsList />
      <Container className={styles.container}>
        <OffersHighlights />
        <OutPartners />
      </Container>
      <Footer />
    </PageLoading>
  )
}

export default Categoreis
