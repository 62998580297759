import React from 'react'

const Arrow = ({ width="32", height="32", color="#F39200", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 67 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M45.6543 26.9149L33.7181 39.0851L21.782 26.9149" stroke={color} strokeWidth="2.80851" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Arrow
