import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TagManager from 'react-gtm-module'
import LazyLoad from "react-lazyload";
import PageLoading from "components/pageLoading";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Logo from "./images/logo--vasco-alegria.png";
import LogoGigante from "./images/logo--gigante.png";

import Picture1 from "./images/card--picture-1.jpeg";
import Picture2 from "./images/card--picture-2.jpeg";
import Picture3 from "./images/card--picture-3.jpeg";

import AquaRio from "./images/partner--aquario.png";
import Centauro from "./images/partner--centauro.png";
import Cinemark from "./images/partner--cinemark.png";
import Deezer from "./images/partner--deezer.png";
import EnglishLive from "./images/partner--english-live.png";
import FreeFire from "./images/partner--freefire.png";
import Havaianas from "./images/partner--havaianas.png";
import iFood from "./images/partner--ifood.jpg";
import Kinoplex from "./images/partner--kinoplex.png";
import LigaRetro from "./images/partner--ligaretro.png";
import McDonalds from "./images/partner--mcdonalds.png";
import NerdStore from "./images/partner--nerdstore.png";
import Petz from "./images/partner--petz.png";
import PontoFrio from "./images/partner--ponto.png";
import PsStore from "./images/partner--psstore.jpg";
import Rappi from "./images/partner--rappi.jpg";
import Renner from "./images/partner--renner.png";
import RioStar from "./images/partner--riostar.png";
import Spotify from "./images/partner--spotify.jpg";
import StudioGeek from "./images/partner--studio-geek.png";
import Tinder from "./images/partner--tinder.png";
import Uber from "./images/partner--uber.jpeg";
import UberEats from "./images/partner--uber-eats.png";
import UCI from "./images/partner--uci.png";
import XBoxLive from "./images/partner--xbox-live.png";

import styles from "./index.module.scss";

import { classNames } from "utils";
import { showLoginModal, setPartnerSettings } from "store/actions";
import CookieConsent from "react-cookie-consent";
import { getPartners } from "services/api";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 830 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  large_mobile: {
    breakpoint: { max: 830, min: 580 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  medium_mobile: {
    breakpoint: { max: 580, min: 400 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const VascoHomePage = () => {
  const [partners, setPartners] = useState([]);
  const partnerSettings = useSelector((state) => state.global.partner)

  const loadPartners = () => {
    getPartners()
      .then((response) => {
        setPartners(response.partners);
      })
      .catch((error) => {
        console.error({ error });
      });
  };

  const dispatch = useDispatch();

  const partnerVasco = partners.find((partner) => partner.provider === "vasco");

  useEffect(() => {
    loadPartners();
  }, []);

  const handleSetPartnerSettings = useCallback(() => {
    dispatch(setPartnerSettings(partners.find((partner) => partner.provider === "vasco")))
  }, [dispatch, partners])

  useEffect(() => {
    if(partners.length > 0) {
      handleSetPartnerSettings()
    }
  }, [handleSetPartnerSettings, partners.length])

  useEffect(() => {
    if (partnerSettings && partnerSettings.google_tag_manager_id) {
      const tagManagerArgs = {
        gtmId: partnerSettings.google_tag_manager_id
      }
      TagManager.initialize(tagManagerArgs)
    } else {
      const tagManagerArgs = {
        gtmId: "GTM-KQ36KMB"
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [partnerSettings])

  return (
    <PageLoading>
      <div className={styles.outer}>
        <div className={styles.wrapper}>
          <div className={styles.hero}>
            <div className={styles.heroDescription}>
              <LazyLoad once>
                <img src={Logo} alt="logo--vasco-alegria" />
              </LazyLoad>

              <p>
                O clube de vantagens do Vasco pra você ter{" "}
                <span>muitos benefícios</span> e{" "}
                <span>sem precisar acumular pontos</span>.
              </p>
              <h3>Descontos e vantagens para você que é Gigante.</h3>
              <div
                className={styles.button}
                onClick={() =>
                  dispatch(showLoginModal(true, "vasco", partnerVasco))
                }
              >
                entrar no vasco +alegria
              </div>
            </div>
          </div>

          <div className={styles.operation}>
            <header className={styles.operationHeader}>
              <h2>atuação vasco +alegria</h2>
              <p>
                Sócio, confira os benefícios e experiências únicas
                <br />
                para os associados do clube de vantagens Vasco +Alegria.
              </p>
            </header>

            <div className={styles.operationCards}>
              <div className={styles.card}>
                <div className={styles.cardPicture}>
                  <LazyLoad once>
                    <img src={Picture1} alt="" />
                  </LazyLoad>
                </div>
                <div className={styles.cardContent}>
                  <h2>Clube de vantagens</h2>
                  <p>
                    Simples e moderno, o clube oferece{" "}
                    <span>benefícios exclusivos</span> em produtos, serviços e
                    entretenimento.
                  </p>
                </div>
              </div>

              <div className={styles.card}>
                <div className={styles.cardPicture}>
                  <LazyLoad once>
                    <img src={Picture2} alt="" />
                  </LazyLoad>
                </div>
                <div className={styles.cardContent}>
                  <h2>Benefícios imediatos</h2>
                  <p>
                    São cinemas, parques, restaurantes, serviços e muito mais
                    pra você aproveitar com até <span>50% de desconto</span>.
                  </p>
                </div>
              </div>

              <div className={styles.card}>
                <div className={styles.cardPicture}>
                  <LazyLoad once>
                    <img src={Picture3} alt="" />
                  </LazyLoad>
                </div>
                <div className={styles.cardContent}>
                  <h2>Experiências exclusivas</h2>
                  <p>
                    O programa oferece experiências e descontos{" "}
                    <span>exclusivos</span> para o cliente.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.gigante}>
              <LazyLoad once>
                <img src={LogoGigante} alt="Logo Gigante" />
              </LazyLoad>

              <h2>
                Exclusivo para
                <br />
                sócios do Vasco
              </h2>
            </div>
          </div>

          <div className={styles.partners}>
            <header className={styles.operationHeader}>
              <h2>nossos parceiros</h2>
            </header>

            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={2000}
              className={classNames(styles.partnersCarousel, "oi-carousel")}
            >
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={PsStore} alt="Nosso parceiro PS Store" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={AquaRio} alt="Nosso parceiro Aqua Rio" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Centauro} alt="Nosso parceiro Centauro" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Cinemark} alt="Nosso parceiro Cinemark" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Deezer} alt="Nosso parceiro Deezer" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={EnglishLive} alt="Nosso parceiro English Live" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={FreeFire} alt="Nosso parceiro FreeFire" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Havaianas} alt="Nosso parceiro Havaianas" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={iFood} alt="Nosso parceiro iFood" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Kinoplex} alt="Nosso parceiro Kinoplex" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={LigaRetro} alt="Nosso parceiro Liga Retrô" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={McDonalds} alt="Nosso parceiro McDonalds" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={NerdStore} alt="Nosso parceiro Nerdstore" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Petz} alt="Nosso parceiro Petz" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={PontoFrio} alt="Nosso parceiro PontoFrio" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={PsStore} alt="Nosso parceiro PS Store" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Rappi} alt="Nosso parceiro Rappi" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Renner} alt="Nosso parceiro Renner" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={RioStar} alt="Nosso parceiro Rio Star" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Spotify} alt="Nosso parceiro Spotify" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={StudioGeek} alt="Nosso parceiro StudioGeek" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Tinder} alt="Nosso parceiro Tinder" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={Uber} alt="Nosso parceiro Uber" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={UberEats} alt="Nosso parceiro Uber Eats" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={UCI} alt="Nosso parceiro UCI" />
                </LazyLoad>
              </div>
              <div className={styles.carouselItem}>
                <LazyLoad once>
                  <img src={XBoxLive} alt="Nosso parceiro XBox Live" />
                </LazyLoad>
              </div>
            </Carousel>
          </div>

          <div className={styles.footer}>
            <div className={styles.howitworks}>
              <div className={styles.howitworksDescription}>
                <h2>como funciona</h2>

                <p>
                  Poderão participar do clube Vasco +Alegria todos os torcedores
                  ativos do sócio gigante nos planos Norte a Sul, Caldeirão,
                  Caldeirão Mais, Colina, Colina Mais, Sócio Dinamite e Sócios
                  Estatutários do Clube.
                </p>
                <p>
                  Os torcedores não pagam nenhuma mensalidade para ter acesso a
                  todos os benefícios do clube, basta fazer o cadastro
                  utilizando o código recebido como sócio torcedor e/ou sócio
                  estatuário e todos os descontos estarão disponíveis
                  imediatamente.
                </p>
                <h3>Já é sócio? Cadastre-se já no Vasco +Alegria</h3>
                <p>
                  Acesse a área{" "}
                  <a
                    aria-label="lick para sócio gigante"
                    href="https://www.sociogigante.com/minha-conta/meu-plano"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.anchor}
                  >
                    Meu Plano
                  </a>{" "}
                  no sociogigante.com e anote seu código de ativação Vasco
                  +Alegria. No seu primeiro acesso utilize esse código e depois
                  é só aproveitar as ofertas e benefícios que o Vasco +Alegria
                  oferece!
                </p>

                <div
                  className={styles.button}
                  onClick={() => dispatch(showLoginModal(true, "vasco", {}))}
                >
                  entrar no vasco +alegria
                </div>
              </div>
            </div>

            <div className={styles.faq}>
              <div className={styles.faqDescription}>
                <h2>Ainda ficou com duvidas?</h2>
                <p className={styles.faqDescription1}>
                  Acesse nosso FAQ que temos mais informações para você.
                </p>
                <a
                  aria-label="link para suporte mais alegria"
                  className={styles.button}
                  href="https://maisalegriahelp.zendesk.com/hc/pt-br/categories/4403852720909-Vasco-Alegria"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  saiba mais
                </a>
                <p className={styles.faqDescription2}>
                  Se você ainda não é sócio, acesse{" "}
                  <a
                    aria-label="link para sócio gigante"
                    href="https://www.sociogigante.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    sociogigante.com
                  </a>{" "}
                  para aproveitar o Vasco +Alegria e outros benefícios que só um
                  sócio gigante tem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Concordo"
        cookieName="AU_consent"
        containerClasses={styles.cookieConsent}
        contentClasses={styles.contentConsent}
        buttonWrapperClasses={styles.btnWrapperConsent}
        buttonClasses={styles.btnConsent}
        expires={150}
      >
        Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
        experiência em nossos serviços e recomendar conteúdo de seu interesse.
        Ao utilizar nossos serviços, você concorda com tal monitoramento. Veja a
        nossa{" "}
        <a
          aria-label="link para política de privacidade"
          href="https://clube-alegria-prod.s3.us-east-2.amazonaws.com/b308f4b7-1552-44c1-ac4b-e27bd70f904f"
          target="_blank"
          rel="noopener noreferrer"
        >
          política de privacidade
        </a>
        .
      </CookieConsent>
    </PageLoading>
  );
};

export default VascoHomePage;
