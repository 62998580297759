import { makeStyles } from '@material-ui/core'
import people from './images/people-background.svg'
import peopleMobile from './images/people-background-mobile.svg'

export const useStyles = makeStyles((theme) => ({
  peopleBackground: {
    backgroundImage: `url(${people})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    height: "680px",
    p: 2,
    textAlign: 'center',
    color: 'white',
    marginRight: '-2px',
    [theme.breakpoints.down('sm')]: {
      height: 300,
      backgroundImage: `url(${peopleMobile})`,
      marginRight: 0,
      backgroundSize: 'cover',
    },
  },
  containerLoginLogo: {
    background: 'white',
    width: '200px',
    height: '233px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 20px 20px',
    zIndex: 3,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25);',
    [theme.breakpoints.down('sm')]: {
      width: '190px',
      height: '205px',
    },
  },
  loginLogo: {
    maxWidth: '135px',
    maxHeight: '100px',
  },
  containerForm: {
    marginTop: '-15px',
    borderRadius: '10px',
    width: '358px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    color: 'gray',
    paddingBottom: '40px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
      paddingTop: '32px',
      paddingBottom: '16px',
    },
  },
  formHeader: {
    fontFamily: 'NeuePlak-Regular',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    color: '#333',
    textAlign: 'center',
    marginTop: '32px',
    marginBottom: '12px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formFieldWrapper: {
    width: '264px',
    [theme.breakpoints.down('md')]: {
      width: '230px',
    },
  },
  formFirstInput: {
    marginBottom: '20px',
  },
  forgotPassword: {
    cursor: 'pointer',
    fontFamily: 'NeuePlak-Regular',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    color: '#575756',
    textAlign: 'right',
    width: '100%',
  },
  recaptchaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '64px',
  },
  recaptcha: {
    transform: 'scale(0.8)',
    transformOrigin: '0 0',
  },
  recaptchaError: {
    color: 'red',
    fontSize: '0.7em',
    textAlign: 'center',
    marginBottom: '16px',
  },
  footerButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '234px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '143px',
      marginTop: '54px',
    },
  },
  messageContainer: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  know: {
    display: 'flex',
    fontFamily: 'NeuePlak-Regular',
    fontSize: 34,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '40px',
    color: '#333',
    marginTop: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginTop: '20px',
    },
  },
}))
