import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MenuIcon, QuestionMarkIcon } from "assets/icons";
import styles from "./index.module.scss";

const ShowSubCategories = ({ subCategories }) => {
  const history = useHistory();

  const categgorie = subCategories[0].slug;

  return (
    <ul className={styles.list}>
      {subCategories.length> 0 && subCategories[0]?.sub_categories.map(sbuCtg => (
        <li
          key={sbuCtg.slug}
          onClick={() => history.push(`/categorias/${categgorie}?q=${sbuCtg.slug}`)}
        >
          {sbuCtg.name}
        </li>
      ))}
    </ul>
  )
}

const ShowAllCategories = ({ categories }) => {
  const history = useHistory();

  return (
    <div className={styles.allCategories} style={{ columns: categories.length }}>
      {categories.map(ctg => (
        <ul key={ctg.slug}>
          <li className={styles.principal}>{ctg.name}</li>
          {ctg.sub_categories.map(sub =>(
            <li
              key={sub.slug}
              className={styles.subCategorie}
              onClick={() => history.push(`/categorias/${ctg.slug}?q=${sub.slug}`)}
            >
              {sub.name}
            </li>
          ))}
        </ul>
      ))}
    </div>
  )
}

const AllCategories = () => {
  const categories = useSelector((state) => state.global.interestAreas);
  const partnerSettings = useSelector((state) => state.global.partner);
  const partner = useSelector((state) => state.global.partner)

  const [categorieSelected, setCategoriesSelected] = useState(null);

  const categoriesFiltered = categories.filter(ctg => ctg.slug === categorieSelected);


  const handleCategorieSelected = (ctg) => {
    if (categorieSelected === ctg) {
      setCategoriesSelected(null);
      return;
    }

    setCategoriesSelected(ctg);
  }

  const handleClickOut = () => {
    setCategoriesSelected(null)
  }

  return (
    <div className={styles.container}>
      {categorieSelected && <div className={styles.isOpen} onClick={() => handleClickOut()}/>}
      <div
        className={styles.wrapper}
        onClick={() =>handleCategorieSelected("todos")}
      >
        <div className={categorieSelected === "todos"
          ? styles.isRelative
          : ""}>
          <MenuIcon
            color={partnerSettings?.main_colors?.primary_color}
            width="20"
            height="18"
          />
        </div>
        <div>
          <p className={styles.item}>Todos</p>
          {categorieSelected && categorieSelected === "todos"
              && <ShowAllCategories categories={categories} />}
        </div>
      </div>
      <div className={styles.categorie}>
        {categories?.map(categorie => (
          <div
            key={categorie.slug}
            onClick={() =>handleCategorieSelected(categorie.slug)}
            className={categorieSelected === categorie.slug ? styles.isRelative : ""}
          >
            <p className={styles.item}>{categorie.name}</p>
            {categorieSelected && categorieSelected === categorie.slug
              && <ShowSubCategories subCategories={categoriesFiltered} />}
          </div>
        ))}
      </div>
      <div
        className={styles.wrapper}
        onClick={() => window.open(partner.faq_url, "_black")}
        >
        <QuestionMarkIcon
          color={partnerSettings?.main_colors?.primary_color}
          width="18"
          height="18"
        />
        <p>Dúvidas</p>
      </div>
    </div>
  )
}

export default AllCategories;
