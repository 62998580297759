import React, { useEffect } from 'react'
import PageLoading from 'components/pageLoading'

import Header from '../../pages/carrinho/components/header'

import Footer from 'components/footer'
import Finish from './components/Finish'

import { useSelector, useDispatch } from 'react-redux'
import withReducer from 'store/withReducer'
import reducer from './store/reducers'
import * as Actions from './store/actions'
import * as GeneralActions from 'store/actions'

import { isLoggedIn } from 'store/selectors'
import { useHistory } from 'react-router-dom'

import { ordersValidate } from 'services/api/index'
import PersonalData from './components/PersonalData'
import { Grid } from '@material-ui/core'
import styles from './index.module.scss'
import NewAddress from './components/NewAddress'
import Phone from './components/Phone'
import ReviewProducts from './components/ReviewProducts'

const OrderCheckout = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentStep = useSelector(
    ({ orderCheckout }) => orderCheckout.currentStep,
  )
  const orderFinalValue = useSelector(
    ({ orderCheckout }) => orderCheckout.orderFinalValue,
  )

  const logged = useSelector(isLoggedIn)

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem('AU_header'))
    if (!logged && !userHeader) {
      history.push('/partners')
    }
  }, [logged, history])

  useEffect(() => {
    dispatch(GeneralActions.getUser())
  }, [dispatch])

  useEffect(() => {
    dispatch(Actions.setCurrentStep('confirmation'))
  }, [dispatch])

  useEffect(() => {
    const orderInfo = JSON.parse(localStorage.getItem('AU_order'))
    if (orderInfo && orderInfo.order) {
      ordersValidate(orderInfo.order).then((response) => {
        if (response.status === 'fail') {
          history.push(`/carrinho`)
        }
      })
    }

    if (orderInfo) {
      if (logged) {
        dispatch(Actions.refreshCartItems())
        dispatch(Actions.getGiftVouchers())
        dispatch(Actions.getCards())
      }

      dispatch(Actions.setCurrentStep('confirmation'))

      if (orderFinalValue && orderFinalValue < 1 && orderFinalValue > 0) {
        history.push('/carrinho')
      }
    } else {
      history.push('/ofertas')
    }
  }, [dispatch, logged, history, orderFinalValue])

  useEffect(() => {
    const orderInfo = JSON.parse(localStorage.getItem('AU_order'))
    dispatch(Actions.setUseGiftVouchers(orderInfo?.use_gift_vouchers))
  }, [dispatch])

  return (
    <PageLoading>
      <Header />
      {currentStep.key === 'finish' ? (
        <Finish {...props} />
      ) : (
        <Grid container className={styles.grid}>
          <Grid item xs={12} sm={6} className={styles.gridData}>
            <PersonalData />
            <NewAddress />
            <Phone />
          </Grid>
          <Grid item xs={12} sm={6} spacing={2} sx={{ marginLeft: 2 }}>
            <ReviewProducts />
          </Grid>
        </Grid>
      )}
      <Footer />
    </PageLoading>
  )
}

export default withReducer('orderCheckout', reducer)(OrderCheckout)
