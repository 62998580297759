import React from "react";
import Footer from "components/footer";
import Header from "components/header";

// import BlackNovemberBanner from "components/blackNovember"

const Layout = ({ children }) => {
  return (
    <>
      {/* <BlackNovemberBanner /> */}
      <Header hideSearchBar={true} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
