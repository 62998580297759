import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from '../../useStyles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useForm } from 'react-hook-form'
import Axios from 'axios'
import { Box, CircularProgress } from '@mui/material'
import { PasswordFieldController, TextFieldController } from 'components/forms'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button } from '@material-ui/core'
import { Alert } from 'react-bootstrap'
import * as Actions from 'store/actions'
import { useDispatch } from 'react-redux'

const FirstAccess = ({ selectedPartner, setIsFirstAccess }) => {
  const classes = useStyles()
  const recaptchaSignInRef = useRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const [recaptchaVerifySignIn, setRecaptchaVerifySignIn] = useState(false)
  const [errorIdSignIn, setErrorIdSignIn] = useState(null)
  const [loadingFirstAccess, setLoadingFirstAccess] = useState(false)
  const [recaptchaHasLoadedSignIn, setRecaptchaHasLoadedSignIn] =
    useState(false)
  const [requestErrors, setRequestErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)

  const recaptchaChangeSignIn = (value) => {
    setRecaptchaVerifySignIn(value)
    if (errorIdSignIn === 'invalid_recaptcha' && Boolean(value)) {
      setErrorIdSignIn(null)
    }
  }

  const validFormSignIn = () => {
    if (!recaptchaVerifySignIn) {
      setErrorIdSignIn('invalid_recaptcha')
      return false
    } else {
      setErrorIdSignIn(null)
      recaptchaSignInRef.current.reset()
    }

    return true
  }

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: 'onSubmit',
  })

  const onSubmitRegister = async (user) => {
    if (!validFormSignIn()) return null
    try {
      setLoadingFirstAccess(true)
      return await Axios.post(`${process.env.REACT_APP_API_URL}/users`, {
        user: {
          cpf: user.cpf,
          password: user.password,
          provider: selectedPartner?.provider,
        },
        recaptchaVerifySignIn,
      })
        .then((res) => {
          if (res.data.access_code) {
            dispatch(
              Actions.showLoginModal(
                false,
                selectedPartner.name,
                selectedPartner,
              ),
            )
            history.push(`/cadastro/${res.data.token}`)
            setLoadingFirstAccess(false)
          } else {
            history.push(`/cadastro/partner/${res.data.token}`)
            setLoadingFirstAccess(false)
          }
        })
        .catch((error) => {
          setLoadingFirstAccess(false)

          if (error.response) {
            setRequestErrors([error.response.data.error_message])
          } else {
            setRequestErrors(['Erro ao se conectar com o servidor'])
          }
        })
    } catch (error) {
      setRequestErrors(['Erro ao se conectar com o servidor'])
    }
  }
  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowAlert(true)
    }
  }, [requestErrors])

  return (
    <Box>
      <Box className={classes.formHeader}>Meu primeiro acesso</Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitRegister)}
        className={classes.form}
      >
        <Box className={classes.formFieldWrapper}>
          <Box className={classes.formFirstInput}>
            <TextFieldController
              label="CPF"
              name="cpf"
              mask="999.999.999-99"
              control={control}
              errors={errors}
              rules={{ pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/ }}
            />
          </Box>

          <Box>
            <PasswordFieldController
              name="password"
              label="Código de acesso"
              control={control}
              errors={errors}
            />
          </Box>
        </Box>

        <Box className={classes.recaptchaContainer} sx={{ mt: 2 }}>
          {!recaptchaHasLoadedSignIn && (
            <CircularProgress color="inherit" style={{ alignSelf: 'center' }} />
          )}
          <ReCAPTCHA
            ref={recaptchaSignInRef}
            className={classes.recaptcha}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={recaptchaChangeSignIn}
            asyncScriptOnLoad={() => setRecaptchaHasLoadedSignIn(true)}
          />
        </Box>
        {errorIdSignIn === 'invalid_recaptcha' && (
          <Box className={classes.recaptchaError}>
            Por favor, verifique que não é um robô
          </Box>
        )}
        {showAlert && (
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            Atenção! <br />
            {requestErrors && (
              <ul>
                {requestErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}

        <Button
          type="submit"
          disabled={loadingFirstAccess}
          style={{
            borderRadius: '9999px',
            backgroundColor: '#141414',
            color: 'white',
            paddingRight: 32,
            paddingLeft: 32,
            fontFamily: 'NeuePlak-Regular',
            fontSize: 12,
          }}
        >
          CADASTRAR
          {loadingFirstAccess && (
            <Box marginLeft={2}>
              <CircularProgress color="inherit" size={'16px'} />
            </Box>
          )}
        </Button>
        <Button onClick={() => setIsFirstAccess(false)}>Voltar</Button>
      </Box>
    </Box>
  )
}

export default FirstAccess
