import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

// import Header from "components/header";
import NewLayoutHeader from "components/NewLayoutHeader";
import AllCategories from "components/AllCategories";
import Footer from "components/footer";

import OfertasHome from "./components/ofertas-home";
// import UserOffers from "components/userOffers";
// import MostRecents from "components/mostRecents";
// import BiggestDiscount from "components/biggestDiscount";
import HomeBanner from "./components/home-banner";
import CategoriesHightlight from "components/categoriesHighlight";
import OffersHighlights from "components/offersHighlight";
import SectionNewsAndDiscount from "components/sectionNewsAndDiscount";
import MostVisited from "components/mostVisited";
import OutPartners from "components/ourPartners";
import FooterBanner from "./components/footer-banner";

// import InterestAreas from "components/InterestAreas";
import PageLoading from "components/pageLoading";
import { getLoginProvider } from "store/selectors";
import QuizzesAndSearch from "components/QuizAndSearch";
import ProgrammersHighLights from "components/programmersHighlitghts";

import { isLoggedIn } from "store/selectors";

const Ofertas = (props) => {
  const history = useHistory();
  const busca = props.match.params.busca;
  const filtro = props.match.params.filtro;
  const userProvider = useSelector(getLoginProvider);
  const logged = useSelector(isLoggedIn);

  useEffect(() => {
    const userHeader = JSON.parse(localStorage.getItem("AU_header"));
    if (!logged && !userHeader) {
      history.push("/partners");
    }
  }, [history, logged]);


  return (
    <PageLoading>
      {/* <Header /> */}
      <NewLayoutHeader />
      <AllCategories />
      <QuizzesAndSearch />
      {/* {filtro !== "parceiros" && <InterestAreas />} */}

      {busca || filtro ? (
        <OfertasHome busca={busca} filtro={filtro} />
      ) : (
        <Container className="mb-4">
          <HomeBanner />
          <CategoriesHightlight />
          <OffersHighlights />
          <SectionNewsAndDiscount />
          {/* <UserOffers /> */}
          {/* <MostRecents /> */}
          {/* <BiggestDiscount /> */}
          <MostVisited />
          <div style={{ margin: "3em 0 1em" }}>
            <ProgrammersHighLights />
          </div>
          <OutPartners />
        </Container>
      )}
      {userProvider === "" && <FooterBanner />}
      <Footer />
    </PageLoading>
  );
};

export default Ofertas;
