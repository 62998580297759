import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import styles from "./index.module.scss";
import { classNames } from "utils";
import { useSelector } from "react-redux";
import { getLoginProvider } from "store/selectors";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "transparent",
    padding: 10,
    paddingBottom: 20,
    boxShadow: "0 0",
  },
}));

export default function ({ ticket }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(({ user }) => user.info);
  const partner = useSelector(({ global }) => global.partner);
  const userProvider = useSelector(getLoginProvider);
  const [benefitTier, setBenefitTier] = useState("");
  const [prices, setPrices] = useState([]);

  const [anchorElement, setAnchorElement] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    if (user && user.benefit_tier) {
      setBenefitTier(user.benefit_tier.toLowerCase());
    }
  }, [user]);

  useEffect(() => {
    let ticketCopy = JSON.parse(JSON.stringify(ticket));

    if (user && user.benefit_tier) {
      ticketCopy.prices = ticketCopy.prices.filter(
        (price) => price.partner_id === user.partner_id
      );
    }

    let orderedPrice = [];
    if (ticketCopy && ticketCopy.prices) {
      ["Ouro", "Prata", "Bronze"].forEach((tierName) => {
        let tierIndex = ticketCopy.prices.findIndex(
          (price) => price.benefit_tier?.name === tierName
        );

        if (Number.isInteger(tierIndex)) {
          orderedPrice = [...orderedPrice, ticketCopy.prices[tierIndex]];
        }
      });
    }
    setPrices(orderedPrice.filter((element) => element !== undefined));
  }, [ticket, user]);

  const openPopover = (event) => {
    setAnchorElement(event.currentTarget.parentElement);
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setAnchorElement(null);
    setPopoverOpen(false);
  };

  const popoverProps = {
    id: "mouse-over-popover",
    open: popoverOpen,
    anchorEl: anchorElement,
    classes: {
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    disableRestoreFocus: true,
    onClose: closePopover,
  };

  return (
    <div className="text-right mt-2 mr-4">
      <Typography
        aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        className={styles.benefitTierText}
      >
        Condições para clientes{" "}
        <span className="text-capitalize">{benefitTier}</span>.{" "}
        {userProvider !== "" && (
          <span className={styles.popoverTriggerText} onClick={openPopover}>
            Descubra mais descontos
            {/* {benefitTier.toLowerCase() === "ouro" ? "Saiba mais" : "Descubra mais descontos"} */}
          </span>
        )}
      </Typography>

      {benefitTier.toLowerCase() === "ouro" ? (
        <Popover {...popoverProps}>
          <div className={styles.popoverContainer}>
            <Typography
              className={classNames("text-center", styles.defaultText)}
            >
              <span style={{ fontSize: "1em" }}>Parabéns!</span>
              <br />
              Você já tem acesso aos maiores descontos do Clube {
                partner?.name
              }{" "}
              +alegria! Aproveite!
            </Typography>
          </div>
        </Popover>
      ) : (
        <Popover {...popoverProps}>
          <div className={styles.popoverContainer}>
            <Typography
              className={classNames("text-center", styles.defaultText)}
            >
              <p>Seu desconto é para clientes <span>{benefitTier}</span></p>
              <ul className="mt-3">
                {prices &&
                  prices.map((price, key) => (
                    <li className="font-weight-bold" key={key}>
                      Cliente {price.benefit_tier?.name}:{" "}
                      {parseInt(price.percent_discount)}% OFF
                    </li>
                  ))}
              </ul>
              <span>
                Acesse a área "
                <span
                  onClick={() => history.push("/usuario/gerenciar-assinaturas")}
                  className={styles.subscriptionPageLink}
                >
                  Gerenciar Assinatura
                </span>
                " e veja como mudar de categoria e ter mais descontos
              </span>
            </Typography>
          </div>
        </Popover>
      )}
    </div>
  );
}
