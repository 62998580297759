import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Modal, Alert } from 'react-bootstrap'

import * as Actions from 'store/actions'

import { PasswordFieldController, TextFieldController } from 'components/forms'

import CircularProgress from '@material-ui/core/CircularProgress'

import ReCAPTCHA from 'react-google-recaptcha'

import '../../index.css'

import {
  loginRequest,
  getPartnerSettings,
  getSettings,
  getAllQuiz,
  getAllSurveys,
} from 'services/api/index'
import Axios from 'axios'
import { Button } from '@material-ui/core'
import { useStyles } from '../../useStyles'

const AlreadyClient = ({ selectedPartner, setIsFirstAccess }) => {
  const history = useHistory()
  const recaptchaRef = useRef()
  const dispatch = useDispatch()
  const [requestErrors, setRequestErrors] = useState([])
  const [loadingSignIn, setLoadingSignIn] = useState(false)
  const [recaptchaVerify, setRecaptchaVerify] = useState(false)
  const [loginMessage, setLoginMessage] = useState(false)
  const [recaptchaHasLoaded, setRecaptchaHasLoaded] = useState(false)
  const [showSignInAlert, setShowSignInAlert] = useState(false)

  useEffect(() => {
    if (Array.isArray(requestErrors) && requestErrors.length > 0) {
      setShowSignInAlert(true)
    }
  }, [requestErrors])

  const [errorId, setErrorId] = useState(null)

  const classes = useStyles()

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    errors: errors2,
  } = useForm({
    reValidateMode: 'onSubmit',
  })

  const validForm = () => {
    if (!recaptchaVerify) {
      setErrorId('invalid_recaptcha')
      return false
    } else {
      setErrorId(null)
      recaptchaRef.current.reset()
    }

    return true
  }

  const recaptchaChange = (value) => {
    setRecaptchaVerify(value)
    if (errorId === 'invalid_recaptcha' && Boolean(value)) {
      setErrorId(null)
    }
  }

  const onSubmit = async (user) => {
    if (!validForm()) return null
    setLoadingSignIn(true)

    loginRequest(
      {
        email: user.email,
        password: user.password,
        provider: selectedPartner?.provider,
      },
      recaptchaVerify,
    )
      .then(async (response) => {
        setLoadingSignIn(true)
        if (response.success) {
          if (response.redirect_to_signup) {
            setLoadingSignIn(true)
            return await Axios.post(`${process.env.REACT_APP_API_URL}/users`, {
              user: {
                cpf: response.cpf,
                password: user.password,
                provider: selectedPartner?.provider,
              },
              recaptchaVerify,
            })
              .then((res) => {
                if (res.data.access_code) {
                  history.push(`/cadastro/${res.data.token}`)
                  setLoadingSignIn(false)
                } else {
                  history.push(`/cadastro/partner/${res.data.token}`)
                  setLoadingSignIn(false)
                }
              })
              .catch((error) => {
                setLoadingSignIn(false)

                if (error.response) {
                  setRequestErrors([error.response.data.error_message])
                } else {
                  setRequestErrors(['Erro ao se conectar com o servidor'])
                }
              })
          } else {
            response.headers.provider = selectedPartner?.name
            dispatch(Actions.userLogin(response))
            dispatch(
              Actions.showLoginModal(
                false,
                selectedPartner?.name,
                selectedPartner,
              ),
            )
            setLoginMessage(true)

            dispatch(Actions.loadApplication())

            setTimeout(() => {
              getSettings().then((result) => {
                dispatch(Actions.setGlobalSettings(result))
              })
              getPartnerSettings().then((result) => {
                dispatch(Actions.setPartnerSettings(result))
              })
              dispatch(Actions.showNotificationsModal(true))
              dispatch(
                Actions.showLoginModal(
                  false,
                  selectedPartner?.name,
                  selectedPartner,
                ),
              )

              getAllQuiz().then((data) => {
                const { quizzes } = data

                if (quizzes.length) {
                  const hasQuiz = quizzes.find((quiz) => quiz.area === 'login')

                  if (hasQuiz) {
                    localStorage.setItem(
                      'hasQuiz',
                      JSON.stringify({
                        area: 'login',
                        quiz: hasQuiz,
                        alreadyAnswer: false,
                      }),
                    )
                  }
                }
              })

              getAllSurveys().then((data) => {
                const { surveys } = data

                if (surveys.length) {
                  const hasSearch = surveys.find(
                    (survey) => survey.area === 'login',
                  )

                  if (hasSearch) {
                    localStorage.setItem(
                      'hasSearch',
                      JSON.stringify({
                        area: 'login',
                        survey: hasSearch,
                        alreadyAnswer: false,
                      }),
                    )
                  }
                }
              })

              if (sessionStorage.getItem('AU_redirect')) {
                history.push(sessionStorage.getItem('AU_redirect'))
                sessionStorage.removeItem('AU_redirect')
              } else {
                history.push('/ofertas')
              }

              setLoadingSignIn(false)

              setLoginMessage(false)
              dispatch(
                Actions.showVideoModal(
                  true,
                  selectedPartner?.name,
                  selectedPartner,
                ),
              )
            }, 2000)

            setLoginMessage(false)
            dispatch(
              Actions.showVideoModal(
                true,
                selectedPartner?.name,
                selectedPartner,
              ),
            )
          }
        } else {
          setLoadingSignIn(false)

          if (response.errors) {
            setRequestErrors(response.errors)
          } else {
            setRequestErrors([response.error])
          }
        }
      })
      .catch((err) => {
        setRequestErrors(['Erro ao se conectar com o servidor'])
      })
  }

  const openForgotPassword = () => {
    dispatch(
      Actions.showLoginModal(false, selectedPartner?.name, selectedPartner),
    )
    dispatch(
      Actions.showForgotPasswordModal(
        true,
        selectedPartner?.provider,
        selectedPartner,
      ),
    )
  }

  return (
    <Box>
      <Box className={classes.formHeader}>Já sou cliente</Box>
      <Box
        component="form"
        onSubmit={handleSubmit2(onSubmit)}
        className={classes.form}
      >
        <Box className={classes.formFieldWrapper}>
          <Box className={classes.formFirstInput}>
            <TextFieldController
              label="E-mail"
              type="email"
              name="email"
              control={control2}
              errors={errors2}
            />
          </Box>

          <Box>
            <PasswordFieldController
              name="password"
              label="Senha"
              control={control2}
              errors={errors2}
            />

            <Box
              onClick={() => openForgotPassword()}
              className={classes.forgotPassword}
            >
              Esqueci minha senha
            </Box>
          </Box>
        </Box>
        <Box className={classes.recaptchaContainer}>
          {!recaptchaHasLoaded && (
            <CircularProgress color="inherit" style={{ alignSelf: 'center' }} />
          )}
          <ReCAPTCHA
            ref={recaptchaRef}
            className={classes.recaptcha}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={recaptchaChange}
            asyncScriptOnLoad={() => setRecaptchaHasLoaded(true)}
          />
        </Box>
        {errorId === 'invalid_recaptcha' && (
          <Box className={classes.recaptchaError}>
            Por favor, verifique que não é um robô
          </Box>
        )}

        {showSignInAlert && (
          <Alert
            variant="danger"
            onClose={() => setShowSignInAlert(false)}
            dismissible
          >
            Atenção! <br />
            {requestErrors && (
              <ul>
                {requestErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            )}
          </Alert>
        )}

        <Box className={classes.footerButtons}>
          <Button
            aria-label="acessar conta"
            type="submit"
            disabled={loadingSignIn}
            variant="contained"
            style={{
              borderRadius: '9999px',
              backgroundColor: '#141414',
              color: 'white',
              paddingRight: 32,
              paddingLeft: 32,
              fontFamily: 'NeuePlak-Regular',
              fontSize: 12,
            }}
          >
            ACESSAR CONTA{' '}
            {loadingSignIn && (
              <CircularProgress
                color="inherit"
                size={"16px"}
                sx={{marginLeft: 4}}
              />
            )}
          </Button>

          <Button
            onClick={() => setIsFirstAccess(true)}
            style={{
              color: '#141414',
              fontFamily: 'NeuePlak-Regular',
              fontSize: 12,
            }}
          >
            Meu primeiro acesso
          </Button>
        </Box>
      </Box>
      <Modal show={loginMessage} onHide={() => setLoginMessage(false)} centered>
        <Modal.Body className="text-center">
          Bem-vindo(a)! Você está logado.
        </Modal.Body>
      </Modal>
    </Box>
  )
}

export default AlreadyClient
